import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ChecklistTaskResponseStatusEnum } from '../../openapi/atlantis';
import { FileResponse } from '../../openapi/dropbox';
import { deleteFromDropbox } from '../../slices/DropboxSlice';
import { fetchLoanChecklistDetail } from '../../slices/LoanSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import DefaultLoader from '../DefaultLoader';

type ChecklistFileProps = {
  file: FileResponse;
};

const ChecklistFile: React.FC<ChecklistFileProps> = ({ file }) => {
  const dispatch = useAppDispatch();
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>();

  const { borrowerDetail, checklist } = useAppSelector((state) => ({
    borrowerDetail: state.loan.borrowerDetail,
    checklist: state.loan.checklistDetail,
  }));

  const checklistId = checklist?.id;
  const isFileDeletable =
    checklist?.status !== ChecklistTaskResponseStatusEnum.Accepted;

  const onDeleteFile = async () => {
    setIsDeletingFile(true);

    const isFileDeleted = await dispatch(
      deleteFromDropbox(borrowerDetail!, file, checklistId!),
    );

    if (isFileDeleted) {
      await dispatch(
        fetchLoanChecklistDetail(borrowerDetail?.id!, checklistId!),
      );
    }

    setIsDeletingFile(false);
  };

  return (
    <div className='flex flex-row items-center justify-between mt-3 md:mt-4 p-4 border border-silver rounded-xl'>
      <p className='font-inter-medium text-base text-reskin-primary-dark w-11/12 ml-3'>
        {file.filename}
      </p>
      {isFileDeletable && (
        <button onClick={onDeleteFile} disabled={!!isDeletingFile}>
          {isDeletingFile ? (
            <DefaultLoader iconSize={10} noPadding />
          ) : (
            <FontAwesomeIcon
              icon={faXmark}
              title='Delete'
              fontSize={20}
              fontWeight={300}
              className='text-reskin-primary-dark'
            />
          )}
        </button>
      )}
    </div>
  );
};

export default ChecklistFile;
