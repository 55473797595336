import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { cn } from '../../utils/classUtils';
import FieldErrorMessage from './FieldErrorMessage';

export interface ControlledConsentInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  labelComponent?: React.ReactElement;
  linkComponent?: React.ReactElement;
  customClassName?: string;
}

const ControlledConsentInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  labelComponent,
  linkComponent,
  customClassName,
  shouldUnregister = true,
  ...controllerProps
}: ControlledConsentInputProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  return (
    <div>
      <div className='flex flex-row items-center'>
        <button
          className={cn('flex flex-row items-center', customClassName)}
          onClick={() => onChange(!value)}
          type='button'
        >
          {value ? (
            <FontAwesomeIcon
              icon={faSquareCheck}
              fontSize={16}
              className='text-primary-gray'
            />
          ) : (
            <FontAwesomeIcon
              icon={faSquare}
              fontSize={16}
              className='text-primary-gray'
            />
          )}
          {labelComponent && (
            <div className='pl-2 justify-center'>{labelComponent}</div>
          )}
        </button>
        {linkComponent && linkComponent}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledConsentInput;
