import {
  faCloudArrowDown,
  faEye,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ChecklistTaskResponse,
  ChecklistTaskResponseStatusEnum,
  Question,
} from '../../openapi/atlantis';
import { FileApi, FileResponse } from '../../openapi/dropbox';
import ErrorService from '../../services/ErrorService';
import { deleteFromDropbox } from '../../slices/DropboxSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { getDropboxConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ConfirmationModal from '../ConfirmationModal';
import IconCircleButton from '../IconCircleButton';

type DocumentCardProps = {
  file: FileResponse;
  hideDelete?: boolean;
};

const DocumentCard: React.FC<DocumentCardProps> = ({
  file,
  hideDelete = false,
}) => {
  const { borrowerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);

  const { borrowerDetail, checklists } = useAppSelector((state) => ({
    borrowerDetail: state.loan.borrowerDetail,
    checklists: state.loan.checklists,
  }));

  const checklist = useMemo((): ChecklistTaskResponse | undefined => {
    const checklist = (checklists || []).find(
      (checklist) =>
        checklist.fileReferences?.references?.some(
          (checklistFile) => checklistFile?.fileId === file.id,
        ),
    );

    return checklist;
  }, [checklists, file.id]);

  const checklistId = checklist?.id;
  const checklistContent: Question = checklist?.content || {};

  const isDocumentDeletable =
    !hideDelete &&
    checklist?.status !== ChecklistTaskResponseStatusEnum.Accepted;

  const onViewDocument = async () => {
    try {
      const { data: fileUrl } = await new FileApi(
        await getDropboxConfiguration(),
      ).getFileUrl(file.id!);

      const newTab = window.open(fileUrl, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        dispatch(
          showErrorToast(
            'Pops are blocked by your browser. Please allow popups for this site.',
          ),
        );
      }
    } catch (e: any) {
      ErrorService.notifyIgnoreHandled('Unable to download a file', e, {
        file: {
          fileId: file.id,
        },
      });
    }
  };

  const onDeleteDocument = async () => {
    try {
      setIsDeletingFile(true);
      await dispatch(deleteFromDropbox(borrowerDetail!, file, checklistId));
      dispatch(showSuccessToast('Document deleted successfully'));
    } catch (e: any) {
      dispatch(showErrorToast('Unable to delete the file'));
      ErrorService.notify('Unable to delete the checklist document', e, {
        checklistDocument: { ...file },
      });
    } finally {
      setIsDeletingFile(false);
      setIsOpen(false);
    }
  };

  return (
    <div className='px-3 border border-silver hover:border-reskin-primary-dark rounded-xl'>
      <div className='flex flex-row items-center justify-between py-3'>
        <div>
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon
              icon={faFile}
              fontSize={18}
              className='text-reskin-silver-400'
            />
            <p className='font-inter-light text-sm md:text-base text-reskin-primary-dark ml-2'>
              {file.filename}
            </p>
          </div>
          {file.createdAt && (
            <div className='font-inter-regular text-xs md:text-sm text-primary-gray mt-1'>
              {DateTime.fromMillis(file.createdAt!).toFormat(
                'MMM dd, yyyy, hh:mm a',
              )}
            </div>
          )}
        </div>
        <div className='flex gap-2.5'>
          <IconCircleButton
            icon={
              <FontAwesomeIcon
                icon={faEye}
                className='text-sm font-light text-reskin-primary-dark'
              />
            }
            toolTipText='View Doc'
            onClick={onViewDocument}
          />
          <IconCircleButton
            icon={
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                className='text-sm font-light text-reskin-primary-dark'
              />
            }
            toolTipText='Download'
            onClick={onViewDocument}
          />
          {isDocumentDeletable && (
            <IconCircleButton
              icon={
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-sm font-light text-[##F84C6C]'
                />
              }
              toolTipText='Delete'
              onClick={() => setIsOpen(true)}
              variant='danger'
            />
          )}
        </div>
      </div>
      {checklistId && (
        <div className='py-3 font-inter-light text-sm text-reskin-primary-dark border-t border-silver'>
          Task:{' '}
          <span className='font-inter-regular text-reskin-primary-blue'>
            {!!checklistContent?.title && (
              <div
                onClick={() => {
                  navigate(
                    `/pre-approval-form/${borrowerId}/tasks/${checklistId}`,
                  );
                }}
                className='cursor-pointer inline-block'
              >
                {checklistContent?.title}
              </div>
            )}
          </span>
        </div>
      )}
      <ConfirmationModal
        isOpen={isOpen}
        title='Are you sure you want to delete this document?'
        subtitle='It will be permanently removed from this document storage view.'
        size='large'
        onConfirm={onDeleteDocument}
        onClose={() => setIsOpen(false)}
        confirmButtonText='Delete'
        confirmButtonVariant='danger'
        confirmButtonLeftIcon={
          <FontAwesomeIcon icon={faTrashCan} className='text-white' />
        }
        isDisabled={isDeletingFile}
        isSubmitting={isDeletingFile}
      />
    </div>
  );
};

export default DocumentCard;
